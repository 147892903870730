import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  splitByChar,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const StoreConsumptionReportPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getJobCards();
    getMachines();
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const [jobcard, setJobcard] = useState([]);
  const [defaultJobCard, setDefaultJobCard] = useState([{ label: 'All', value: '' }]);
  const [jobCardOptions, setJobCardOptions] = useState([]);
  const [machinData, setMachineData] = useState([]);
  const [defaultMachine, setDefaultMachine] = useState([{ label: 'All', value: '' }]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    let match = [];
    match = tableData.find((o, i) => {
      if (o.id == id) {
        return true;
      }
    });
    setShowData(match);
    setIsModalOpen(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const postApi = () => {
    const postData = {
      data: {
        jobcard,
        machinData,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let jobcardVal = jobcard.toString();
    let machinDataVal = machinData.toString();
    showLoader();
    axios
      .get(
        baseUri() +
          'store-consumptions/report?machine=' +
          machinDataVal +
          '&jobCard=' +
          jobcardVal +
          '&from=' +
          from +
          '&to=' +
          to,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setItemData(response.data.data.result);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.result);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Store Consumption Details</h5>
          <table className="pop-up-table">
            <tr>
              <td>Date </td>
              <td>{dateFormat(showData.date)}</td>
            </tr>
            <tr>
              <td>GRA Date </td>
              <td>{dateFormat(showData.gra_date)}</td>
            </tr>
            <tr>
              <td>Group Name </td>
              <td>{showData.group_name}</td>
            </tr>

            <tr>
              <td>Intend No. </td>
              <td>{showData.intend_number}</td>
            </tr>
            <tr>
              <td>Item Name </td>
              <td>{showData.item_name}</td>
            </tr>
            <tr>
              <td>Machine </td>
              <td>{showData.machine_name}</td>
            </tr>
            <tr>
              <td>Customer </td>
              <td>{showData.name}</td>
            </tr>
            <tr>
              <td>Operation Name</td>
              <td>{showData.operation_name}</td>
            </tr>
            <tr>
              <td>Qty </td>
              <td>
                Total Qty: {showData.quantity} <br />
                Rate Per Qty: {showData.rate_per_qty} <br />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const jobcardChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setJobcard(selected);
    setDefaultJobCard(event);
    pullDownReset('jobCard');
  };

  const machineChange = (event) => {
    let selected2 = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected2.push(item.value);
    });
    setMachineData(selected2);
    setDefaultMachine(event);
    pullDownReset('machine');
  };

  const clearFormData = () => {
    setFrom('');
    setTo('');
    setJobcard([]);
    setMachineData([]);
    setDefaultJobCard([{ label: 'All', value: '' }]);
    setDefaultMachine([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getJobCards = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          'job-cards?sort[0]=id:desc&fields[0]=greyWeightPerMtr&populate[processInstruction][fields][0]=piNumber&populate[processInstruction][populate][customer][fields][0]=name&populate[processInstruction][populate][customerSort][fields][0]=sortName&populate[processOrder][fields][0]=id&populate[processOrder][populate][customer][fields][0]=name&populate[processOrder][populate][customersSort][fields][0]=sortName&populate[processedGreys][fields][0]=metersProcessed',
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let jobcardoptions = [];
        response.data.data.forEach(function (item, index) {
          if (item.attributes.processInstruction.data || item.attributes.processOrder.data) {
            let totalMeters = 0;
            if (item.attributes.processedGreys.data) {
              item.attributes.processedGreys.data.forEach(function (grey, key) {
                totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
              });
            }

            let cusSort = {};
            let customerName = '';
            let customerSortName = '';
            if (item.attributes.processInstruction.data) {
              cusSort = {
                label: item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName,
                value: item.attributes.processInstruction.data.attributes.customerSort.data.id,
              };
              customerName = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
              customerSortName =
                item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
            } else {
              cusSort = {
                label: item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName,
                value: item.attributes.processOrder.data.attributes.customersSort.data.id,
              };
              customerName = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
              customerSortName = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
            }

            jobcardoptions.push({
              label: item.id + ' - ' + customerName + ' - ' + customerSortName,
              value: item.id,
              materiel: totalMeters,
              sort: cusSort,
            });
            //jobcardoptions.push({ label: item.id, value: item.id, materiel: totalMeters });
          }
        });
        setJobCardOptions(jobcardoptions);
        hideLoader();
      })
      .catch((error) => {});
  };

  const getMachines = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machines?sort[0]=id:desc&[fields][0]=machineCode&[fields][1]=description&populate[groupOps][fields][0]=id`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
        });
        setMachineOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="btn-rad ml-10"
            onClick={() => {
              handleModalOpen(row.id);
            }}
          >
            {' '}
            <EvaIcon name="eye" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'Consump ID',
      selector: (row) => `${row.Consump_ID}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer',
      selector: (row) => `${row.Cust_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sort Code',
      selector: (row) => `${row.Sort_Code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sort Name',
      selector: (row) => `${row.Sort_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'JCN',
      selector: (row) => `${row.JCN}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot No',
      selector: (row) => `${row.Lot_Number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Code',
      selector: (row) => `${row.Machine_Code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Name',
      selector: (row) => `${row.machine_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Group Name',
      selector: (row) => `${row.group_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Consump Date',
      selector: (row) => `${row.Consump_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Intend No',
      selector: (row) => `${row.Intend_number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Code',
      selector: (row) => `${row.Item_Code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Name',
      selector: (row) => `${row.Item_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Operation',
      selector: (row) => `${row.operation_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Qty',
      selector: (row) => `${row.Qty ? row.Qty : ''}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rate',
      selector: (row) => `${row.Rate_Per_Qty ? row.Rate_Per_Qty : ''}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'Consump_ID',
    'Cust_Name',
    'Sort_Code',
    'Sort_Name',
    'JCN',
    'Lot_Number',
    'Machine_Code',
    'machine_name',
    'group_name',
    'operation_name',
    'Consump_Date',
    'Intend_number',
    'Item_Code',
    'Item_Name',
    'Qty',
    'Rate_Per_Qty',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      {popup}
      <SEO title="Store Consumption Report Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Store Consumption Report Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Lot Number</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => jobcardChange(e)}
                    options={jobCardOptions}
                    id="jobCard"
                    name="jobCard"
                    placeholder="Select LotNumber"
                    value={defaultJobCard}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Store Consumption Report</header>
            <CardBody>
              {showDownload && (
                <p>
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StoreConsumptionReportPage;
